import moment from "moment";
import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useParams } from "react-router-dom";
import NoDataTable from "../../common/noDataTable";
import { ApiGet } from "../../helpers/API/ApiData";
import { ErrorToast } from "../../helpers/Toast";
import SVG from "react-inlinesvg";
import Loader from "../../media/Loader.gif";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { FaEye } from "react-icons/fa";

function CustomerInvoice() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const fetchData = async () => {
    setLoading(true);
    await ApiGet("/customer/get/" + id)
      .then((res) => {
        console.log("customer/get/invoice", res?.data?.data[0]?.Invoice_Data);
        setData(res?.data?.data[0]?.Invoice_Data);
        setLoading(false);
      })
      .catch((err) => ErrorToast(err?.message));
  };
  useEffect(() => {
    fetchData();
  }, []);
  const columns = [
    {
      dataField: "invoiceNo",
      text: "invoice no",
      sort: true,
      formatter: (cell, row) => {
        return <div>{cell || "-"}</div>;
      },
    },
    {
      dataField: "User_Data",
      text: "Bill From",
      sort: true,
      formatter: (cell, row) => {
        return <div>{cell[0]?.name || "-"}</div>;
      },
    },
    {
      dataField: "createdAt",
      text: "created At",
      sort: true,
      formatter: (cell, row) => {
        return <div>{cell ? moment(cell).format("DD-MM-YYYY") : "-"}</div>;
      },
    },
    {
      dataField: "dueDate",
      text: "Due date",
      sort: true,
      formatter: (cell, row) => {
        return <div>{cell ? moment(cell).format("DD-MM-YYYY") : "-"}</div>;
      },
    },
    {
      dataField: "Item_Data",
      text: "amount",
      sort: true,
      formatter: (cell, row) => {
        return <div>{cell?.length > 0 ? cell[0]?.total : "-"}</div>;
      },
    },
    {
      dataField: "payment",
      text: "payment",
      sort: true,
      formatter: (cell, row) => {
        return <div>{row?.paid ? row?.paymentMethod : "-"}</div>;
      },
    },
    {
      dataField: "action",
      text: "view",
      sort: true,
      formatter: (cell, row) => {
        return (
          <>
            <a
              title="Edit customer"
              className="btn btn-icon btn-secondary btn-hover-primary btn-sm me-3"
              // onClick={() => editBtnClick(row)}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <FaEye />
              </span>
            </a>
          </>
        );
      },
    },
  ];
  return (
    <>
      <BootstrapTable
        wrapperClasses="table-responsive"
        bordered={false}
        classes="table table-head-custom table-vertical-center overflow-hidden"
        bootstrap4
        keyField="_id"
        data={data || []}
        columns={columns}
        noDataIndication={() => {
          if (loading) {
            return (
              <div className="d-flex justify-content-center">
                <img src={Loader} alt="" className="h-100px" />
              </div>
            );
          } else {
            return <NoDataTable />;
          }
        }}
      />
    </>
  );
}

export default CustomerInvoice;
