/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };
  const signout = () => {
    localStorage.clear();
    window.location.href = "/";
  };
  return (
    <>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/users", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/users">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
            </span>
            <span className="menu-text">Users</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/customers", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/customers">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
            </span>
            <span className="menu-text">Customers</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/suppliers", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/suppliers">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
            </span>
            <span className="menu-text">Suppliers</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/post", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/post">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Outgoing-box.svg"
                )}
              />
            </span>
            <span className="menu-text">Post</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/notification", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/notification">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/General/Notifications1.svg"
                )}
              />
            </span>
            <span className="menu-text">Notification</span>
          </NavLink>
        </li>

        {/* <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/error",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/error">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Earth.svg")} />
              </span>
              <span className="menu-text">Manage Categories</span>
              <i className="menu-arrow mt-2" />
            </NavLink>
            <div className="menu-submenu ">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li
                  className="menu-item  menu-item-parent"
                  aria-haspopup="true"
                >
                  <span className="menu-link">
                    <span className="menu-text">Manage Categories</span>
                  </span>
                </li>
                <li
                  className={`menu-item ${getMenuItemActive("/category_List")}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/category_List">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text"> Main Categories</span>
                  </NavLink>
                </li>
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/subCategory_List"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/subCategory_List">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text"> Sub Categories</span>
                  </NavLink>
                </li>
                <li
                  className={`menu-item ${getMenuItemActive("/software_List")}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/software_List">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text"> Software</span>
                  </NavLink>
                </li>
              </ul>
            </div>
          </li> */}
        <li
          className={`menu-item ${getMenuItemActive("", false)}`}
          aria-haspopup="true"
        >
          <div className="menu-link" onClick={() => signout()}>
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Sign-out.svg")}
              />
            </span>
            <span className="menu-text">Sign Out</span>
          </div>
        </li>
      </ul>
    </>
  );
}
