import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ApiGet } from "../../../../helpers/API/ApiData";
import { ErrorToast, SuccessToast } from "../../../../helpers/Toast";

export function MixedWidget1({ className }) {
  document.title = "Dashboard | Tap Digital";
  const history = useHistory();
  const [accountdata, setaccountdata] = useState({});
  const fetchData = async () => {
    await ApiGet("/count")
      .then((res) => {
        console.log("count", res);
        setaccountdata(res?.data?.data?.count);
      })
      .catch(async (err) => {
        ErrorToast(err?.message);
      });
  };
  console.log("accountdata", accountdata);
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <div className="d-flex justify-content-between mb-4">
        <div className="title">
          <div className="fs-20px fw-bolder">Dashboard</div>
          <div>
            <span
              role="button"
              onClick={() => history.push("/dashboard")}
              className="text-hover-info text-muted"
            >
              Home
            </span>{" "}
            -{" "}
            <span className="text-muted" role="button">
              {" "}
              Dashboard
            </span>
          </div>
        </div>
      </div>

      <div className="row">
        <div className={` ${className} col-xxl-4 col-lg-6 col-md-8 col-sm-12`}>
          <div className="card card-custom bg-gray-200">
            <div className="card-body p-0 position-relative overflow-hidden">
              <div
                id="kt_mixed_widget_1_chart"
                className="card-rounded "
                style={{
                  height: "260px",
                  backgroundImage:
                    "url(https://i.pinimg.com/originals/74/7f/8e/747f8eff926a33853b0ee69089c5d4d5.jpg)",
                  backgroundSize: "cover",
                }}
              ></div>

              <div className="card-spacer mt-n25">
                <div className="row m-0">
                  <div className="col bg-light-primary px-6 py-8 rounded-xl mb-7">
                    <span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2">
                      <a className="text-primary font-weight-bold font-size-h6 mt-2">
                        {accountdata?.user}
                      </a>
                    </span>
                    <a
                      className="text-primary font-weight-bold font-size-h6 mt-2"
                      onClick={() => history.push("/users")}
                    >
                      Users
                    </a>
                  </div>
                  <div className="col bg-light-success px-6 py-8 rounded-xl ms-7 mb-7">
                    <span className="svg-icon svg-icon-3x svg-icon-success d-block my-2">
                      <a className="text-success font-weight-bold font-size-h6 mt-2">
                        {accountdata?.customer}
                      </a>
                    </span>
                    <a
                      className="text-success font-weight-bold font-size-h6 mt-2"
                      onClick={() => history.push("/customers")}
                    >
                      Customers
                    </a>
                  </div>
                </div>
                <div className="row m-0">
                  <div className="col bg-light-danger px-6 py-5 rounded-xl d-flex justify-content-between">
                    <a
                      className="text-danger font-weight-bold font-size-h6 mt-1"
                      onClick={() => history.push("/suppliers")}
                    >
                      Suppliers
                    </a>
                    <span className="svg-icon svg-icon-3x svg-icon-danger d-block my-2">
                      <a className="text-danger font-weight-bold font-size-h6 mt-2">
                        {accountdata?.supplier}
                      </a>
                    </span>
                  </div>
                </div>
              </div>

              <div className="resize-triggers">
                <div className="expand-trigger">
                  <div style={{ width: "411px", height: "461px" }} />
                </div>
                <div className="contract-trigger" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
