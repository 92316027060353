import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import SupplierPersonalInfo from "../../Components/SupplierPersonalInfo";
import SupplierExpense from "../../Components/SupplierExpense";
import { ApiGet } from "../../../helpers/API/ApiData";
import { ErrorToast } from "../../../helpers/Toast";
import { Toaster } from "react-hot-toast";

function SupplierDetails() {
  const history = useHistory();
  const [data, setData] = useState({});
  const [toggle, setToggle] = useState("personal");
  const { id } = useParams();
  const fetchData = async () => {
    await ApiGet("/supplier/get/" + id)
      .then((res) => {
        console.log("supplier/get", res?.data?.data[0]);
        setData(res?.data?.data[0]);
      })
      .catch((err) => ErrorToast(err?.message));
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <div className="d-flex justify-content-between mb-4">
        <div className="title">
          <div className="fs-20px fw-bolder">Suppliers Details</div>
          <div>
            <span
              role="button"
              onClick={() => history.push("/dashboard")}
              className="text-hover-info text-muted"
            >
              Home
            </span>{" "}
            -{" "}
            <span
              role="button"
              onClick={() => history.push("/suppliers")}
              className="text-hover-info text-muted"
            >
              {" "}
              Suppliers
            </span>{" "}
            -{" "}
            <span role="button" className="text-muted">
              {" "}
              Supplier details
            </span>
          </div>
        </div>
      </div>
      <div className="card mb-5 mb-xl-10">
        <div className="card-body pt-9 pb-0">
          <div className="d-flex flex-wrap mb-3">
            <div className="me-7 mb-4">
              <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                <img
                  className="img-fluid w-100 mw-lg-100"
                  src="https://preview.keenthemes.com/metronic/theme/html/demo1/dist/assets/media/users/300_21.jpg"
                  alt="profile"
                />
              </div>
            </div>
            <div>
              <div className="mb-2">
                <div className="d-flex flex-column">
                  <div className="d-flex align-items-center mb-2">
                    <a className="text-gray-900 text-hover-primary fs-2 fw-bolder me-1">
                      {data?.supplierName || "-"}
                    </a>
                  </div>
                  <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                    <a className="d-flex align-items-center text-gray-400 text-hover-primary mb-2">
                      {data?.email || "-"}
                    </a>
                  </div>
                </div>
                <div className="d-flex flex-wrap flex-stack">
                  <div className="d-flex flex-column flex-grow-1">
                    <div className="d-flex flex-wrap justify-content-between">
                      <div className="d-flex flex-wrap">
                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="d-flex align-items-center">
                            <div className="fs-2 fw-bolder text-danger">0</div>
                          </div>
                          <div className="fw-bold fs-6 text-gray-400">
                            You will give
                          </div>
                        </div>
                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="d-flex align-items-center">
                            <div className="fs-2 fw-bolder text-success">0</div>
                          </div>
                          <div className="fw-bold fs-6 text-gray-400">
                            You gave
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex overflow-auto h-55px">
            <ul class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
              <li class="nav-item" onClick={() => setToggle("personal")}>
                <a
                  class={`nav-link text-active-primary me-6 ${toggle ===
                    "personal" && "active"}`}
                  data-toggle="tab"
                >
                  Personal Info.
                </a>
              </li>
              <li class="nav-item" onClick={() => setToggle("invoice")}>
                <a
                  class={`nav-link text-active-primary me-6 ${toggle ===
                    "invoice" && "active"}`}
                  data-toggle="tab"
                >
                  Expenses
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="d-flex flex-column flex-column-fluid">
          <div className="d-flex flex-column-fluid">
            <div className="container-fluid">
              {toggle === "personal" ? (
                <SupplierPersonalInfo />
              ) : toggle === "invoice" ? (
                <SupplierExpense />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SupplierDetails;
