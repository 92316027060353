import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useHistory } from "react-router-dom";
import NoDataTable from "../../../common/noDataTable";
import Swal from "sweetalert2";
import Pagination from "@material-ui/lab/Pagination";
import { ApiDelete, ApiGet, ApiPost, ApiPut, ApiUpload } from "../../../helpers/API/ApiData";
import { ErrorToast, SuccessToast } from "../../../helpers/Toast";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import moment from "moment";
import { Dropdown, Form, Modal } from "react-bootstrap";
import Loader from "../../../media/Loader.gif";
import swal from "sweetalert2";
import { CSVLink } from "react-csv";
import { FaEye } from "react-icons/fa";

function Post() {
  document.title = "Posts | Tap Digital";
  const date = new Date();
  const history = useHistory();
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [totalpage, settotalpage] = useState(0);
  const [currentpage, setcurrentpage] = useState(1);
  const [pagesize, setpagesize] = useState(10);
  const [searching, setsearching] = useState("");
  const [countryData, setCountryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [exportData, setExportData] = useState([]);
  const columns = [
    {
      dataField: "user",
      text: "user name",
      sort: true,
      formatter: (cell, row) => {
        return (
          <div
          // className="text-hover-primary"
          // role="button"
          >
            {cell[0]?.name || "-"}
          </div>
        );
      },
    },
    {
      dataField: "customer",
      text: "customer name",
      sort: true,
      formatter: (cell, row) => {
        return <div>{cell[0]?.billingName || "-"}</div>;
      },
    },
    {
      dataField: "customer",
      text: "customer address",
      sort: true,
      formatter: (cell, row) => {
        const result = cell[0];
        return (
          <div>
            {result?.streetName ? `${result?.streetName}, ` : ""}
            {result?.City ? `${result?.City}, ` : ""}
            {result?.country || ""}
            {!result?.streetName && !result?.City && !result?.country && "-"}
          </div>
        );
      },
    },
    {
      dataField: "customer",
      text: "Customer email",
      sort: true,
      formatter: (cell, row) => {
        return <div className="">{cell[0]?.email || "-"}</div>;
      },
    },
    {
      dataField: "draftinvoice",
      text: "invoice no",
      sort: true,
      formatter: (cell, row) => {
        return <div className="">{cell[0]?.invoiceNo || "-"}</div>;
      },
    },
    {
      dataField: "createdAt",
      text: "created At",
      sort: true,
      formatter: (cell, row) => {
        return <div>{moment(cell).format("DD-MM-YYYY")}</div>;
      },
    },
    {
      dataField: "postStatus",
      text: "status",
      sort: true,
      formatter: (cell, row) => {
        return (
          <span
            className={`label font-weight-bold label-lg label-inline ${cell === 0 ? "label-light-warning" : "bg-light-success text-success"
              }`}
            role="button"
            title="Click to Change Status"
            onClick={() => {
              if (cell === 0) {
                swal
                  .fire({
                    text: `Are you sure you want to change Status ?`,
                    icon: "warning",
                    showCancelButton: true,
                    showConfirmButton: true,
                    confirmButtonText: `Yes, ${cell !== 0 ? "Pending" : "Delivered"}`,
                    confirmButtonColor: "#D72852",
                    cancelButtonColor: "transparent",
                    cancelButtonText: "No, Cancel",
                  })
                  .then((res) => {
                    if (res.isConfirmed) {
                      changeStatus(row);
                    }
                  });
              }
            }}
          >
            {cell === 0 ? "Pending" : "Delivered"}
          </span>
        );
      },
    },
    {
      dataField: "draftinvoice",
      text: "preview",
      sort: true,
      formatter: (cell, row) => {
        return (
          <div className="text-center">
            <a
              href={cell[0]?.pdfUrl}
              target="_blank"
              className="bg-light px-2 py-0 fs-16px rounded text-primary"
            >
              <FaEye />
            </a>
          </div>
        );
      },
    },
  ];
  const changeStatus = async (row) => {
    let body = {
      postStatus: row?.postStatus === 0 ? "1" : "0",
    };
    await ApiPost("/update/post/" + row?._id, body)
      .then((res) => {
        SuccessToast("Status Updated Successfully !!!");
        fetchData(currentpage, pagesize, searching);
      })
      .catch((err) => console.log("err", err));
  };
  const onPaginationChange = (e, i) => {
    setcurrentpage(i);
    fetchData(i, pagesize, searching);
  };
  const handleonchnagespagination = (e) => {
    setpagesize(e.target.value);
    setcurrentpage(1);
    fetchData(1, parseInt(e.target.value), searching);
  };
  const handlesearch = async (e) => {
    setsearching(e.target.value);
    fetchData(currentpage, pagesize, e.target.value);
  };
  const fetchData = async (page, limit, search) => {
    setLoading(true);
    let body = { page, limit, search };
    await ApiPost("/get/post", body)
      .then(async (res) => {
        console.log("/post/get", res?.data?.data);
        setData(res?.data?.data?.response);
        settotalpage(res?.data?.data?.state?.page_limit);
        setLoading(false);
        getExportData(res?.data?.data?.response);
      })
      .catch((err) => ErrorToast(err?.message));
  };
  const getExportData = async (dataA) => {
    let exportArr = [];
    let a = await dataA?.map((v) => {
      let customerData = v?.customer[0];
      let address = `${customerData?.streetName ? `${customerData?.streetName}, ` : ""}${customerData?.City ? `${customerData?.City}, ` : ""
        }${customerData?.country || ""}`;
      exportArr.push({
        Customer_Name: customerData?.billingName,
        Customer_email: customerData?.email,
        Phone_No: customerData?.mobile,
        address: address,
        UserName: v?.user[0]?.name,
        PDFLink: v?.draftinvoice[0]?.pdfUrl || "",
      });
    });
    setExportData(exportArr);
  };
  console.log("exportData", exportData);
  useEffect(() => {
    fetchData(currentpage, pagesize, searching);
  }, []);
  return (
    <>
      <div className="d-flex justify-content-between mb-4">
        <div className="title">
          <div className="fs-20px fw-bolder">Post List</div>
          <div>
            <span
              role="button"
              onClick={() => history.push("/dashboard")}
              className="text-hover-info text-muted"
            >
              Home
            </span>{" "}
            -{" "}
            <span className="text-muted" role="button">
              {" "}
              Post
            </span>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column flex-column-fluid h-100" id="kt_content">
        <div class="card card-custom">
          <div class="card-header flex-wrap border-0 pt-6 pb-0 w-100">
            <div class="card-title ">
              {/* <h3 class="card-label">Video Playlist</h3> */}
              <div class="input-icon">
                <input
                  type="text"
                  class="form-control bg-light"
                  name="searchText"
                  placeholder="Search by Name"
                  value={searching}
                  onChange={(e) => handlesearch(e)}
                  id="kt_datatable_search_query"
                />
                <span>
                  <i class="flaticon2-search-1 text-muted"></i>
                </span>
              </div>
            </div>

            <div class="card-toolbar">
              <CSVLink data={exportData}>
                <a class="btn bg-dark-theme text-white font-weight-bolder">Export</a>
              </CSVLink>
            </div>
          </div>
          <div className="card-body mb-5">
            <BootstrapTable
              wrapperClasses="table-responsive"
              headerWrapperClasses="border-0"
              bordered={false}
              classes="table table-head-custom table-vertical-center overflow-hidden"
              bootstrap4
              keyField="_id"
              // selectRow={selectRow}
              data={data || []}
              columns={columns}
              // pagination={paginationFactory(options)}
              // defaultSorted={defaultSorted}
              noDataIndication={() => {
                if (loading) {
                  return (
                    <div className="d-flex justify-content-center">
                      <img src={Loader} alt="" className="h-100px" />
                    </div>
                  );
                } else {
                  return <NoDataTable />;
                }
              }}
            // filter={filterFactory()}
            />
            <div class="d-flex justify-content-between  pt-10">
              <div className="my-2">
                <Pagination
                  count={totalpage}
                  page={currentpage}
                  onChange={onPaginationChange}
                  variant="outlined"
                  shape="rounded"
                  className="pagination_"
                />
              </div>
              <div class="my-2">
                <div class="d-flex align-items-center pagination-drpdown">
                  <select
                    class="form-control pagination-drpdown1 dropdownPage"
                    id="kt_datatable_search_status"
                    onChange={(e) => handleonchnagespagination(e)}
                    value={pagesize}
                  >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                    <option value={50}>50</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Post;
