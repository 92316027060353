import React, { Suspense } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { DashboardPage } from "./Dashboard/DashboardPage";
import CustomerDetails from "./pages/Customers/CustomerDetails";
import Customers from "./pages/Customers/Customers";
import Notification from "./pages/Notifications/Notification";
import Post from "./pages/Posts/Post";
import SupplierDetails from "./pages/Suppliers/SupplierDetails";
import Suppliers from "./pages/Suppliers/Suppliers";
import Users from "./pages/Users/Users";
export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/users" component={Users} />

        <ContentRoute path="/customers" component={Customers} />
        <ContentRoute
          path="/customer-details/:id"
          component={CustomerDetails}
        />

        <ContentRoute path="/suppliers" component={Suppliers} />
        <ContentRoute
          path="/supplier-details/:id"
          component={SupplierDetails}
        />
        <ContentRoute path="/post" component={Post} />
        <ContentRoute path="/notification" component={Notification} />

        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
