import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ApiGet } from "../../helpers/API/ApiData";
import { ErrorToast } from "../../helpers/Toast";

function CustomerPersonalInfo() {
  const [data, setData] = useState({});
  const { id } = useParams();
  const fetchData = async () => {
    await ApiGet("/customer/get/" + id)
      .then((res) => {
        console.log("customer/get", res?.data?.data[0]);
        setData(res?.data?.data[0]);
      })
      .catch((err) => ErrorToast(err?.message));
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div>
      <div class="" id="">
        <div class="card-header cursor-pointer mx-0 px-0">
          <div class="card-title m-0">
            <h3 class="fw-bolder m-0">Customer Personal Details</h3>
          </div>
          {/* <a
            class="btn btn-primary align-self-center"
            // onClick={editProfile}
          >
            Edit Profile
          </a> */}
        </div>
        <div class="card-body pt-9 pb-0 mx-0 px-0">
          <div class="row mb-7">
            <label class="col-lg-4 fw-bold text-muted">Name</label>
            <div class="col-lg-8">
              <span class="fw-bolder fs-6 text-gray-800">
                {data?.billingName || "-"}
              </span>
            </div>
          </div>
          <div class="row mb-7">
            <label class="col-lg-4 fw-bold text-muted">Email</label>
            <div class="col-lg-8">
              <span class="fw-bolder fs-6 text-gray-800">
                {data?.email || "-"}
              </span>
            </div>
          </div>
          <div class="row mb-7">
            <label class="col-lg-4 fw-bold text-muted">Phone No.</label>
            <div class="col-lg-8">
              <span class="fw-bolder fs-6 text-gray-800">
                {data?.mobile || "-"}
              </span>
            </div>
          </div>
          <div class="row mb-7">
            <label class="col-lg-4 fw-bold text-muted">Address</label>
            <div class="col-lg-8">
              <span class="fw-bolder fs-6 text-gray-800">
                {data?.streetName && `${data?.streetName}, `}{" "}
                {data?.City && `${data?.City}, `}
                {data?.country}
              </span>
            </div>
          </div>
          <div class="row mb-7">
            <label class="col-lg-4 fw-bold text-muted">Zip Code</label>
            <div class="col-lg-8">
              <span class="fw-bolder fs-6 text-gray-800">
                {data?.postCode || "-"}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomerPersonalInfo;
