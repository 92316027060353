import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { ApiPost } from "../../../helpers/API/ApiData";
import { ErrorToast, SuccessToast } from "../../../helpers/Toast";

export default function Notification() {
	const [newData, setNewData] = useState({ title: "", description: "" });
	const handleChange = (e) => {
		const { name, value } = e.target;
		setNewData({ ...newData, [name]: value })
	}
	const handleSubmit = async () => {
		let body = {
			title: newData?.title,
			description: newData?.description,
			isAllUser: true
		}
		await ApiPost("/notification/sent", body)
			.then(res => { SuccessToast("Notification sent successfully"); setNewData({ title: "", description: "" }) })
			.catch(err => ErrorToast(err?.message))
	}
	return <>
		<div className="card max-w-600px">
			<div className="card-footer border-bottom">
				<h3>Send Notification</h3>
			</div>
			<div className="card-body">
				<div className="row">
					<Form.Group md="12">
						<Form.Label>Title</Form.Label>
						<Form.Control
							type="text"
							placeholder="Enter Title"
							label="Title"
							id="title"
							required
							name="title"
							onChange={handleChange}
							value={newData?.title}
						/>
					</Form.Group>
					<Form.Group md="12">
						<Form.Label>Description</Form.Label>
						<Form.Control
							as="textarea"
							placeholder="Enter Description"
							label="Description"
							id="description"
							required
							name="description"
							onChange={handleChange}
							value={newData?.description}
						/>
					</Form.Group>
				</div>
			</div>
			<div className="card-footer text-end">
				<button className="btn btn-dark"
					onClick={handleSubmit}
				>
					Send
				</button>
			</div>
		</div>
	</>
}
