import moment from "moment";
import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useParams } from "react-router-dom";
import NoDataTable from "../../common/noDataTable";
import { ApiGet } from "../../helpers/API/ApiData";
import { ErrorToast } from "../../helpers/Toast";
import { FaEye } from "react-icons/fa";

function CustomerInvoice() {
  const [data, setData] = useState([]);
  const { id } = useParams();
  const fetchData = async () => {
    await ApiGet("/supplier/get/" + id)
      .then((res) => {
        console.log("supplier/get/expense", res?.data?.data[0]?.Expense_Data);
        setData(res?.data?.data[0]?.Expense_Data);
      })
      .catch((err) => ErrorToast(err?.message));
  };
  useEffect(() => {
    fetchData();
  }, []);
  const columns = [
    {
      dataField: "id",
      text: "No",
      formatter: (cell, row, index) => {
        return <div>{index + 1 || "-"}</div>;
      },
    },
    {
      dataField: "title",
      text: "title",
      sort: true,
      formatter: (cell, row) => {
        return <div>{cell || "-"}</div>;
      },
    },
    {
      dataField: "category",
      text: "category",
      sort: true,
      formatter: (cell, row) => {
        return <div>{cell || "-"}</div>;
      },
    },
    {
      dataField: "price",
      text: "price",
      sort: true,
      formatter: (cell, row) => {
        return <div>{cell || "-"}</div>;
      },
    },
    {
      dataField: "createdAt",
      text: "createdAt",
      sort: true,
      formatter: (cell, row) => {
        return <div>{moment(cell).format("DD-MM-YYYY") || "-"}</div>;
      },
    },
    {
      dataField: "paid",
      text: "payment",
      sort: true,
      formatter: (cell, row) => {
        return <div>{cell ? "paid" : "unpaid"}</div>;
      },
    },
    {
      dataField: "action",
      text: "view",
      sort: true,
      formatter: (cell, row) => {
        return (
          <>
            <a
              title="Edit customer"
              className="btn btn-icon btn-secondary btn-hover-primary btn-sm me-3"
              // onClick={() => editBtnClick(row)}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <FaEye />
              </span>
            </a>
          </>
        );
      },
    },
  ];
  return (
    <>
      <BootstrapTable
        wrapperClasses="table-responsive"
        bordered={false}
        classes="table table-head-custom table-vertical-center overflow-hidden"
        bootstrap4
        keyField="_id"
        data={data || []}
        columns={columns}
        noDataIndication={() => <NoDataTable />}
      />
    </>
  );
}

export default CustomerInvoice;
